import * as React from "react"
import Layout from "../components/layout"
import { Container, Box, Heading, Text, Link, Flex } from "../components/ui"
import ChevronRight from "../components/chevron-right"
import * as styles from "../components/404.css"

export default function PrivacyPolicy() {
  return (
    <Layout title="404: Page not found">
      <Box paddingY={4}>
        <Container>
          <Flex variant="column">
            <Heading variant="mega" className={styles.heading}>
             Privacy Policy
            </Heading>
            <Heading as="h1">Page not found</Heading>
            <div>


Who we share your data with
<br />
If you request a password reset, your IP address will be included in the reset email.
<br /><br />
How long we retain your data

<br />
For users that register on our website (if any), we also store the personal information they provide in their user profile. All users can see, edit, or delete their personal information at any time (except they cannot change their username). Website administrators can also see and edit that information.
<br /><br />
What rights you have over your data
<br />
If you have an account on this site, or have left comments, you can request to receive an exported file of the personal data we hold about you, including any data you have provided to us. You can also request that we erase any personal data we hold about you. This does not include any data we are obliged to keep for administrative, legal, or security purposes.
<br /><br />
Where we send your data<br />
we internally process your data, and we might process it with out third-party logistics providers.
            </div>
          </Flex>
        </Container>
      </Box>
    </Layout>
  )
}
